import React from "react"
import PropTypes from "prop-types"
import {Columns, Column} from 'bloomer';
import {GlobalColors} from "../../styles/GlobalStyles";
import {GlobalStyleHeader, ContainerHeader} from './styles';
//import MenuDesk from "../Menu";
import {NavFullScreenOverlay} from "../NavFullScreenOverlay";

const Header = ({ siteTitle, themeColor }) => {

  return(
    <header>
      <GlobalStyleHeader/>
      <ContainerHeader isFluid>
        <Columns className="columns-responsive columns-margin-side-no">
        <Column className="global-columns-paddign-no flex-center" isSize={{mobile: 12, tablet: 12, desktop: 2, widescreen: 2}}>
          <NavFullScreenOverlay
            nameColor={(themeColor === 'dark') ? 'dark' : 'light'}
            themeColor={(themeColor === 'dark') ? GlobalColors.colorSecondary : GlobalColors.colorPrimary}
          />
        </Column>

        <Column className="no-display-mobile" isSize={{mobile: 0, desktop: 3, widescreen: 3}}>
          {/*<MenuDesk
            itemsMenuDesk={
              [
                {
                  itemMenu: 'Videos',
                  linkTo: 'videos',
                },{
                  itemMenu: 'CV',
                  linkTo: 'cv',
                },{
                  itemMenu: 'Contacto',
                  linkTo: 'contacto',
                }
              ]
            }
          />*/}
        </Column>
        </Columns>
      </ContainerHeader>

    </header>
  )

}

Header.propTypes = {
  siteTitle: PropTypes.string,
  themeColor: PropTypes.string,
}

export default Header
