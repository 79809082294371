/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useStaticQuery, graphql} from 'gatsby';

import {Column, Columns, Container} from 'bloomer';
import {GlobalStyleLayout} from './styles';

import {GlobalStyle} from '../../styles/GlobalStyles';
import {GlobalFonts} from '../../styles/fonts';

import Header from '../Header/index';

import '../../styles/bulma/css/bulma.min.css';
import {ContLibro} from '../Home/styles';

const Layout = ({children, clsOverflow}) => {

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  /*
  console.log('children => ', children);
  console.log('bgColor => ', bgColor);
  console.log('data => ', data.site.siteMetadata);
  */

  useEffect(() => {
    console.log('Layout -> useEffect');
  }, []);

  return (
    <div className={`layout ${clsOverflow ? 'layout-overflow-on' : 'layout-overflow-off'} `}>
      <GlobalStyle/>
      <GlobalFonts/>
      <GlobalStyleLayout/>

      <Header
        siteTitle={data.site.siteMetadata.title}
      />
      <Container isFluid className="wrapper-pages">
        <Columns className="columns-responsive full-height" isMultiline={true} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}}>
            <ContLibro>
              {children}
            </ContLibro>
          </Column>
        </Columns>
      </Container>

    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
