import styled from "styled-components";

export const ContIcoMenuResponsive = styled.div`
  
  position: absolute;
  right: 15px;
  
  width: 50px;
  height: 50px;
  display: none;
  align-items: center;
  justify-content: center;
  
  cursor: pointer;
  
  @media (max-width: 768px){
    display: flex !important;
    right: 15px !important;
  }
  @media (max-width: 1023px){
    display: flex !important;
    right: 25px;
  }
  
`

export const Svg = styled.svg`
  
`
