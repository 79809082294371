import styled, { createGlobalStyle } from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import { Container as ContainerStyled } from 'bloomer'
import {GlobalBackgroundColors} from "../../styles/GlobalStyles";

export const GlobalStyleNavFullScreen = createGlobalStyle`
  #nav-fullscreen{
    position: fixed;
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    /*background-color: ${GlobalBackgroundColors.bgPrimary};*/
    z-index: 99999999;
  }
  .nav-fullscreen-hidden{
    /*display: none;*/
  }
  .nav-fullscreen-show{
    /*display: block;*/
  }
  #nav-fullscreen {
    overflow-y: hidden;
    transition: 0.5s;
  }
  #nav-fullscreen a {
    text-decoration: none;
  }
 
  #nav-fullscreen .dark{
    background-color: ${GlobalBackgroundColors.bgPrimary};
  }
  #nav-fullscreen .light{
    background-color: ${GlobalBackgroundColors.bgSecondary};
  }
    
  @media screen and (max-height: 450px) {
    #nav-fullscreen {overflow-y: auto;}
  }
  @media screen and (min-height: 1024px) {
    #nav-fullscreen {
      height: 0% !important;
    }
  }
`

export const ContainerNavFullScreen = styled(ContainerStyled)`
  overflow: auto;
`

export const Nav = styled.nav`
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 50px;
  background: transparent;
  display: flex;
  flex-direction: column;
  z-index: 1000;
`

export const Link = styled(GatsbyLink)`
  width: 300px;
  min-width: 320px;
  height: 100%;
  margin-left: 15px;
  font-family: NunitoSansRegular;
  color: ${props => props.color};
  text-shadow: 0 0 8px rgba(255, 255, 255, 1);
  display: inline-flex;
  align-items: center;
  justify-content: left;
  text-decoration: none;
  transition: 0.3s;

  &:hover{
    color: ${props => props.color};
    text-shadow: 0 0 8px rgba(227, 13, 24, 1);
  }
  
  &[aria-current]{
    color: ${props => props.color};
    text-shadow: 0 0 8px rgba(227, 13, 24, 1);
  }
  @media (min-width: 320px) and (max-width: 767px){
    font-size: 50px;
    line-height: 70px;
  }
  @media (min-width: 768px) and (max-width: 1024px){
    width: 500px;
    font-size: 50px;
    line-height: 70px;
  }
`

