import styled, {createGlobalStyle} from 'styled-components'
import { Container } from 'bloomer';

export const GlobalStyleHeader = createGlobalStyle`
  header{
    position: absolute;
    width: 100%;
  }
  #divTransitionPage {
    position: fixed;
    top: 0;
    left: 0;
  
    width: 0;
    height: 100vh;
  
    z-index: 99999;
    transition: width 0.5s;
  }
  .clsTransitionPageIn{
    width: 100% !important;
    
    background: red;
  }
  .clsTransitionPageOut{
    right: 0;
    
    width: 0 !important;
  }
`

export const ContainerHeader = styled(Container)`
  position: relative;
  height: 95px;
`

export const ContLogo = styled.div`
  position: relative;
  width: 160px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
`

export const ContNavBarMobile = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 1023px){
    display: none;
  }
`

export const ContRedes = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`
