import React from 'react'
import { ContIcoMenuResponsive, Svg } from './styles-ico-menu'

export const IcoMenu = ({props, colorDark, onClick}) => {

  return(
    <ContIcoMenuResponsive onClick={onClick}>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={40}
        height={40}
        {...props}
      >
        <style>{`.ico-line{fill:${colorDark} }`}</style>
        <g data-name="Grupo 36">
          <path data-name="Rect\xE1ngulo 23" fill="none" d="M0 0h40v40H0z"/>
          <g data-name="Grupo 35" fill={colorDark}>
            <path
              className="ico-line"
              data-name="Trazado 19"
              d="M0 12.9c3.386-.658 6.181-1.4 9.1-1.72 4.36-.492 8.8-.754 13.23-.988 3.144-.167 6.325-.241 9.47-.157 2.676.072 5.327.433 8.2.685-.4.41-.481.613-.625.628-5.158.588-10.313 1.185-15.486 1.717-1.349.139-2.761.035-4.135.114-4.536.262-9.06.616-13.6.81a31 31 0 01-4.861-.377C.862 13.561.53 13.2 0 12.9z"
            />
            <path
              className="ico-line"
              data-name="Trazado 25"
              d="M0 20.9c3.386-.658 6.181-1.4 9.1-1.72 4.36-.492 8.8-.754 13.23-.988 3.144-.167 6.325-.241 9.47-.157 2.676.072 5.327.433 8.2.685-.4.41-.481.613-.625.628-5.158.588-10.313 1.185-15.486 1.717-1.349.139-2.761.035-4.135.114-4.536.262-9.06.616-13.6.81a31 31 0 01-4.861-.377C.862 21.561.53 21.2 0 20.9z"
            />
            <path
              className="ico-line"
              data-name="Trazado 26"
              d="M0 28.9c3.386-.658 6.181-1.4 9.1-1.72 4.36-.492 8.8-.754 13.23-.988 3.144-.167 6.325-.241 9.47-.157 2.676.072 5.327.433 8.2.685-.4.41-.481.613-.625.628-5.158.588-10.313 1.185-15.486 1.717-1.349.139-2.761.035-4.135.114-4.536.262-9.06.616-13.6.81a31 31 0 01-4.861-.377C.862 29.561.53 29.2 0 28.9z"
            />
          </g>
        </g>
      </Svg>
    </ContIcoMenuResponsive>
  )
}
