import styled, { createGlobalStyle }  from 'styled-components'
import bookSide from '../../images/book-side.png';
import bookFront from '../../images/book-front.png';
import bookOpen from '../../images/book-open.png';
import collage from '../../images/collage-home-page-b.png';
import collageM from '../../images/collage-home-page-m.png';
import collageOpen from '../../images/collage-home-open.png';
import {GlobalBackgroundColors, GlobalColors, GlobalFonts} from '../../styles/GlobalStyles';

export const Style = createGlobalStyle`
  .full-height{
    height: 100%;
  }
  .book-side{
    width: 20%;
    height: auto;
  }
  
  @-webkit-keyframes play {
    from { 
      position: relative;
      width: 50%;
      height: 900px;
      top: 4%;
      left: 0%;
    }
    to {
      position: relative;
      width: 100%;
      height: 1800px;
      top: 4%;
      left: 0%;
    }
  }
  
  @-moz-keyframes play {
     from { 
      position: relative;
      width: 50%;
      height: 900px;
      top: 4%;
      left: 0%;
    }
    to {
      position: relative;
      width: 100%;
      height: 1800px;
      top: 4%;
      left: 0%;
    }
  }
  
  @-ms-keyframes play {
     from { 
      position: relative;
      width: 50%;
      height: 900px;
      top: 4%;
      left: 0%;
    }
    to {
      position: relative;
      width: 100%;
      height: 1800px;
      top: 4%;
      left: 0%;
    }
  }
  
  @-o-keyframes play {
    from {
      position: absolute;
      width: 27%;
      top: 7%;
      right: 22%;
    }
    to {
      position: relative;
      width: 100%;
      height: 1800px;
      top: 7%;
      right: 22%;
    }
  }
  
  @keyframes bookOpenBigImg {
     from { 
      position: relative;
      width: 50%;
      height: 900px;
      top: 4%;
      left: 0%;
    }
    to {
      position: relative;
      width: 100%;
      height: 1800px;
      top: 4%;
      left: 0%;
    }
  }
`
export const ContLibro = styled.div`
  width: 100%;
  //height: auto;
  height: calc(100vh - 30px);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BookSide = styled.img.attrs({
  src: bookSide
})`
  width: auto;
  height: calc(100vh - 150px);
  cursor: pointer;
`
export const BookFront = styled.img.attrs({
  src: bookFront
})`
  width: auto;
  height: calc(100vh - 150px);
  cursor: pointer;
`
export const BookOpen = styled.img.attrs({
  src: bookOpen
})`
  width: auto;
  height: calc(100vh - 150px);
`

export const ContBookSide = styled.div`
  width: auto;
  height: calc(100vh - 150px);
  background-image: url(${bookSide});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
  
  @media(min-width: 300px) and (max-width: 767px){
    
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 150px;
    height: calc(100vh - 250px);
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`

export const ContCollage = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100vh - 30px);
  background-image: url(${props => props.bolImgOpen ? collageOpen : collage});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 96%;
    left: 2%;
    background-image: url(${collageM});  
  }
  @media(min-width: 768px) and (max-width: 1023px){
    position: relative;
    width: 100%;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    position: relative;
    width: 100%;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    position: relative;
    width: 100%;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    position: relative;
    width: 100%;
  }
  @media(min-width: 1920px){
    position: relative;
    width: 100%;
  }
`
export const ContBookTitle = styled.div`
  position: relative;
  width: 300px;
  height: calc(100vh - 30px);
  padding: 50px;
  border-radius: 20px;
  background-color: ${GlobalBackgroundColors.bgContentLightest};
  font-family: ${GlobalFonts.fontPrimary};
  font-size: 56px;
  text-align: center;
  color: ${GlobalColors.colorDark};

  cursor: pointer;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  /*&>div{
    height: 100px;
  }*/
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 600px;
    height: 160px;
    padding: 50px;
    font-size: 46px;

    &>div{
      height: 140px;
    }
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 600px;
    height: 180px;
    padding: 50px;
    font-size: 56px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 600px;
    height: 180px;
    padding: 50px 30px;
    font-size: 52px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 600px;
    height: 150px;
    padding: 30px 30px;
    font-size: 52px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 600px;
    height: 150px;
    padding: 30px 30px;
    font-size: 52px;
  }
  @media(min-width: 1920px){
    width: 600px;
    height: 150px;
    padding: 30px 30px;
    font-size: 52px;
  }
`
