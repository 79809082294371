import {createGlobalStyle} from 'styled-components'
import tela from '../../images/tela.jpg';

export const GlobalStyleLayout = createGlobalStyle`
  .layout{
    /*height: calc(100vh - 80px);*/
    height: 100vh;
    background-image: url(${tela});
    background-repeat: no-repeat;
    background-size: cover;
  }
  .wrapper-pages{
    position: relative;
    padding-top: 0;
    /*height: calc(100vh - 95px);*/
    height: 100vh;
    bottom: 0;
    z-index: 999;
    
    @media(min-width: 1024px) and (max-width: 1999px){
      /*height: calc(100vh - 95px);*/
      height: 100vh;
    }
  }
  .layout-overflow-on{
    overflow: auto;
  }
  .layout-overflow-off{
    overflow: hidden;
  }
`
