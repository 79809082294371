import { createGlobalStyle } from 'styled-components'

import TravelingTypewriterEot from '../fonts/TravelingTypewriter.eot';
import TravelingTypewriterTtf from '../fonts/TravelingTypewriter.ttf';
import TravelingTypewriterWoff from '../fonts/TravelingTypewriter.woff';
import TravelingTypewriterWoff2 from '../fonts/TravelingTypewriter.woff2';
import TravelingTypewriterSvg from '../fonts/TravelingTypewriter.svg';

import NunitoSansLightEot from '../fonts/NunitoSansLight.eot';
import NunitoSansLightTtf from '../fonts/NunitoSansLight.ttf';
import NunitoSansLightWoff from '../fonts/NunitoSansLight.woff';
import NunitoSansLightWoff2 from '../fonts/NunitoSansLight.woff2';
import NunitoSansLightSvg from '../fonts/NunitoSansLight.svg';

import NunitoSansRegularEot from '../fonts/NunitoSansRegular.eot';
import NunitoSansRegularTtf from '../fonts/NunitoSansRegular.ttf';
import NunitoSansRegularWoff from '../fonts/NunitoSansRegular.woff';
import NunitoSansRegularWoff2 from '../fonts/NunitoSansRegular.woff2';
import NunitoSansRegularSvg from '../fonts/NunitoSansRegular.svg';

import NunitoSansSemiBoldEot from '../fonts/NunitoSansSemiBold.eot';
import NunitoSansSemiBoldTtf from '../fonts/NunitoSansSemiBold.ttf';
import NunitoSansSemiBoldWoff from '../fonts/NunitoSansSemiBold.woff';
import NunitoSansSemiBoldWoff2 from '../fonts/NunitoSansSemiBold.woff2';
import NunitoSansSemiBoldSvg from '../fonts/NunitoSansSemiBold.svg';

import theartisanEot from '../fonts/theartisan.eot';
import theartisanTtf from '../fonts/theartisan.ttf';
import theartisanWoff from '../fonts/theartisan.woff';
import theartisanWoff2 from '../fonts/theartisan.woff2';
import theartisanSvg from '../fonts/theartisan.svg';



export const GlobalFonts = createGlobalStyle`
  @font-face {
    font-family: 'TravelingTypewriter';
    src: url(${TravelingTypewriterEot});
    src: local('TravelingTypewriter'), local('TravelingTypewriter'),
        url(${TravelingTypewriterWoff2}) format('woff2'),
        url(${TravelingTypewriterWoff}) format('woff'),
        url(${TravelingTypewriterTtf}) format('truetype'),
        url('${TravelingTypewriterSvg}#TravelingTypewriter') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'NunitoSansLight';
    src: url(${NunitoSansLightEot});
    src: local('NunitoSansLight'), local('NunitoSansLight'),
        url(${NunitoSansLightWoff2}) format('woff2'),
        url(${NunitoSansLightWoff}) format('woff'),
        url(${NunitoSansLightTtf}) format('truetype'),
        url('${NunitoSansLightSvg}#NunitoSansLight') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'NunitoSansRegular';
    src: url(${NunitoSansRegularEot});
    src: local('NunitoSansRegular'), local('NunitoSansRegular'),
        url(${NunitoSansRegularWoff2}) format('woff2'),
        url(${NunitoSansRegularWoff}) format('woff'),
        url(${NunitoSansRegularTtf}) format('truetype'),
        url('${NunitoSansRegularSvg}#NunitoSansRegular') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'NunitoSansSemiBold';
    src: url(${NunitoSansSemiBoldEot});
    src: local('NunitoSansSemiBold'), local('NunitoSansSemiBold'),
        url(${NunitoSansSemiBoldWoff2}) format('woff2'),
        url(${NunitoSansSemiBoldWoff}) format('woff'),
        url(${NunitoSansSemiBoldTtf}) format('truetype'),
        url('${NunitoSansSemiBoldSvg}#NunitoSansSemiBold') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  
  @font-face {
    font-family: 'theartisan';
    src: url(${theartisanEot});
    src: local('theartisan'), local('theartisan'),
        url(${theartisanWoff2}) format('woff2'),
        url(${theartisanWoff}) format('woff'),
        url(${theartisanTtf}) format('truetype'),
        url('${theartisanSvg}#theartisan') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
`
