import React from 'react'
import { ContIcoMenuCloseResponsive, Svg } from './styles-ico-close-menu'

export const IcoCloseMenu = ({props, colorDark, onClick}) => {

  return(
    <ContIcoMenuCloseResponsive onClick={onClick}>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={40}
        height={40}
        {...props}
      >
        <style>{`.ico-line{fill:${colorDark} }`}</style>
        <g data-name="Grupo 38">
          <path data-name="Rect\xE1ngulo 23" d="M0 0h40v40H0z" fill="none" />
          <g data-name="Grupo 37" fill="#1d1d1b">
            <path
              className="ico-line"
              data-name="Trazado 25"
              d="M5.463 6.25c2.826 1.977 5.302 3.47 7.561 5.347 3.384 2.793 6.658 5.803 9.905 8.826 2.305 2.144 4.57 4.38 6.695 6.7 1.808 1.974 3.39 4.13 5.206 6.371-.572-.002-.775.08-.886-.013-4.007-3.3-8.02-6.591-11.997-9.94-1.037-.874-1.944-1.961-2.956-2.894-3.34-3.08-6.74-6.086-10.034-9.216A31 31 0 015.85 7.674c-.263-.345-.234-.835-.387-1.425z"
            />
            <path
              className="ico-line"
              data-name="Trazado 26"
              d="M2.752 30.19c2.681-2.168 4.801-4.134 7.229-5.786 3.619-2.48 7.416-4.793 11.218-7.077 2.698-1.622 5.472-3.18 8.289-4.58 2.396-1.192 4.907-2.116 7.562-3.241-.161.55-.138.767-.258.847-4.278 2.939-8.55 5.884-12.869 8.78-1.126.755-2.421 1.325-3.597 2.04-3.883 2.358-7.711 4.792-11.63 7.093a30.995 30.995 0 01-4.468 1.947c-.405.157-.867-.006-1.476-.022z"
            />
          </g>
        </g>
      </Svg>
    </ContIcoMenuCloseResponsive>
  )
}
